export const getFriendlyName = (user) => {
  if (!user) return "Guest User";
  return user.name || user.email;
};

export const validateEmail = (tag) => {
  const email = tag.search || tag.email;
  const emailRegex = /\S+@\S+\.\S+/;
  const valid = emailRegex.test(email.trim());
  return valid;
};
const usernames = [
  "great",
  "awesome",
  "amazing",
  "superb",
  "fantastic",
  "excellent",
  "genius",
  "epic",
  "topnotch",
  "stellar",
  "outstanding",
];
const domains = [
  "course",
  "reviewer",
  "feedback",
  "critic",
  "evaluator",
  "appraiser",
];
const extensions = [".com", ".net", ".org", ".edu", ".gov"];
export const generateRandomEmail = () => {
  //list of possible domain extensions
  const extensions = [".com", ".net", ".org", ".edu", ".gov"];

  const randomUsername =
    usernames[Math.floor(Math.random() * usernames.length)];
  const randomDomain = domains[Math.floor(Math.random() * domains.length)];
  const randomExtenstion =
    extensions[Math.floor(Math.random() * extensions.length)];
  const randomEmail = `${randomUsername}@${randomDomain}${randomExtenstion}`;

  return randomEmail;
};

export const generateRandomURL = () => {
  //list of possible domain extensions

  const randomUsername =
    usernames[Math.floor(Math.random() * usernames.length)];
  const randomDomain = domains[Math.floor(Math.random() * domains.length)];
  const randomExtenstion =
    extensions[Math.floor(Math.random() * extensions.length)];
  const randomURL = `https://${randomUsername}.${randomDomain}${randomExtenstion}`;

  return randomURL;
};

//generate random user name
export const generateUserName = () => {
  const firstNames = [
    "Great",
    "Awesome",
    "Amazing",
    "superb",
    "fantastic",
    "excellent",
    "genius",
    "epic",
    "topnotch",
    "stellar",
    "outstanding",
  ];
  const lastNames = [
    "reviewer",
    "developer",
    "critic",
    "evaluator",
    "appraiser",
    "designer",
  ];

  const randomFirstName =
    firstNames[Math.floor(Math.random() * firstNames.length)];
  const randomLastName =
    lastNames[Math.floor(Math.random() * lastNames.length)];

  const randomName =
    capitalizeFirstLetter(randomFirstName) +
    " " +
    capitalizeFirstLetter(randomLastName);

  return randomName;
};

//convert email to username
export const emailToUsername = (email) => {
  const fname = email.split("@")[0];
  const lname = email.split("@")[1].split(".")[0];
  const username =
    capitalizeFirstLetter(fname) + " " + capitalizeFirstLetter(lname);
  return username;
};
function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const extractDomain = (url) => {
  // const subdomains = window.location.hostname.split(".");
  // const baseDomain = subdomains.slice(-2).join(".");
  if (typeof url === "string") url = new URL(url);
  //if the domain is localhost, return localhost
  if (url.hostname === "localhost") return url.hostname;
  const domain = url.hostname;
  const elems = domain.split(".");
  const iMax = elems.length - 1;

  const elem1 = elems[iMax - 1];
  const elem2 = elems[iMax];

  const isSecondLevelDomain = iMax >= 3 && (elem1 + elem2).length <= 5;
  return (
    (isSecondLevelDomain ? elems[iMax - 2] + "." : "") + elem1 + "." + elem2
  );
};

export const getBaseUrl = (subdomain) => {
  //get the host
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  const port = window.location.port ? ":" + window.location.port : "";
  if (subdomain) {
    if (parts.length > 2) {
      //add course org subdomain
      return `${subdomain}.${parts[1]}.${parts[2]}${port}`;
    } else {
      //add course org subdomain
      return `${subdomain}.${hostname}${port}`;
    }
  } else {
    return `${hostname}${port}`;
  }
};

export const promiseTimeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
