<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useOrganizationStore } from "./stores/organizationStore.js";

const organizationStore = useOrganizationStore();
const pageReady = ref(false);
const route = useRoute();

onMounted(() => {
  organizationStore.getOrganizationInfo();
  organizationStore.getOrganizationFeatures();
  pageReady.value = true;
});
</script>

<template>
  <div class="flex-grow min-h-screen flex flex-col">
    <router-view />
  </div>
</template>

<style>
[v-cloak] {
  display: none;
}
</style>
