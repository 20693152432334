<script setup>
import { ref, onMounted } from "vue";
import { useRoute, RouterLink } from "vue-router";
import WhyUs from "../components/WhyUs.vue";
import logoCloud from "../components/common/LogoCloud.vue";
import ToolCloud from "../components/common/ToolCloud.vue";
import Stats from "../components/common/Stats.vue";
import FeatureComparisonNew from "../components/common/FeatureComparisonNew.vue";
import TestimonialCarousel from "../components/TestimonialCarousel.vue";
import BlogFeed from "../components/common/BlogFeed.vue";

const route = useRoute();

const tools = [
  "Storyline 3",
  "Captivate Classic",
  "Lectora",
  "iSpring",
  "Storyline 360",
  "Captivate",
  "Rise",
  "Gomo",
  "Essemble",
  "PowerPoint",
  "Word",
  "PDFs",
  "SAP Enable Now",
];

const brandColors = [
  "text-green-600",
  "text-blue-700",
  "text-fuchsia-700",
];



const selectedTool = ref("Captivate");
const randomColor = ref("text-fuchsia-700");
const isChanging = ref(false);
const toolIndex = ref(0);
const hasVideo = ref(false);


const isVideoModalOpen = ref(false);
const videoUrl = 'https://www.youtube.com/embed/tFAxIAdISJM?autoplay=1'; // Replace with your video URL




onMounted(() => {
  //pick a new tool from the array every 5 seconds
  setInterval(pickTool, 3000);
});


//pick a new tool from the array
const pickTool = () => {
  isChanging.value = true;
  toolIndex.value++;
  //if we are at the end of the array, start over
  if (toolIndex.value >= tools.length) {
    toolIndex.value = 0;
  }
  selectedTool.value = tools[toolIndex.value];
  let currentColor = randomColor.value;
  randomColor.value = brandColors[Math.floor(Math.random() * brandColors.length)];
  //if the color is the same, pick a new one
  if (currentColor === randomColor.value) {
    randomColor.value = brandColors[Math.floor(Math.random() * brandColors.length)];
  }
  setTimeout(() => {
    isChanging.value = false;
  }, 300);
};

</script>

<template>
  <div class="flex flex-col gap-8 pb-8 home-page">
    <section class="relative isolate px-6 pt-6">
      <div class="mx-auto  ">
        <div class="text-center">
          <h1 class="text-4xl font-bold  text-gray-900 sm:text-6xl">
            Review <transition name="fade">
              <span v-if="!isChanging" class="font-bold max-w-80 min-w-80" :class="randomColor"
                key="{{ selectedTool }}">{{
                  selectedTool }}</span>
            </transition> <br> courses faster <span class="italic">and</span> better
          </h1>
          <p class="mx-auto mt-6 text-md  text-gray-700 max-w-7xl">
            <span class="font-semibold">Effortlessly manage, review, and maintain control over your courses with
              our secure, AI-powered platform.</span> Adapt to your corporate needs with unlimited review cycles, all
            while preserving your brand's integrity. Our flexible features <span class="font-semibold">ensure quality,
              confidentiality, and efficiency</span> at every stage. <br><span class="font-semibold">Your Brand,
              Your
              Process</span><br>We adapt to your workflow, your guidelines, your brand, your legacy.
          </p>
          <div class="mt-10 flex flex-col items-center justify-center gap-y-3">
            <div class="flex flex-col sm:flex-row gap-4">
              <div>
                <router-link to="/join"
                  class="py-2 px-4 rounded-full font-semibold  text-white ring ring-fuchsia-50  bg-fuchsia-600 hover:bg-fuchsia-700 hover:ring-fuchsia-200">
                  <span class="text-sm">Start Your Free Month</span>
                </router-link>
                <p class="text-center text-xs text-gray-600 mt-2"> No credit card required. </p>
              </div>
              <button v-if="hasVideo" @click="isVideoModalOpen = true"
                class="py-2 px-4 rounded-full  ring ring-blue-50 hover:bg-blue-50 hover:ring-blue-100 hover:ring-2">
                <font-awesome-icon icon="fa-solid fa-play" class="text-blue-400" />
                <span class="pl-2 text-sm text-gray-700">Watch video</span>
              </button>
            </div>
            <router-link to="/signup" class="text-sm font-semibold leading-6 text-gray-900">Invited as a
              Reviewer, Developer, or Organization Uploader?
              <span aria-hidden="true">→</span></router-link>
          </div>
        </div>

      </div>
    </section>
    <section>
      <WhyUs />
    </section>
    <section>
      <logoCloud />
    </section>
    <section>
      <Stats />
    </section>

    <section>
      <FeatureComparisonNew />
    </section>
    <section>
      <ToolCloud />
    </section>

    <section id="testimonials">
      <TestimonialCarousel />
      <div class="flex">
        <router-link to="/join"
          class="w-1/2 mx-auto text-center self-center rounded-full bg-[#2196F3] py-3 px-6 text-sm font-semibold text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          See How It Works—Get 1 Month Free!
        </router-link>
      </div>
    </section>
    <section>
      <BlogFeed />
    </section>


  </div>
  <div v-if="isVideoModalOpen" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center"
    @click="isVideoModalOpen = false">
    <div class="bg-white pt-1 p-2 rounded-lg w-11/12 ">
      <div class="flex justify-end p-1">
        <button @click="isVideoModalOpen = false"
          class="px-2 text-gray-500 hover:text-gray-700 rounded border border-gray-200 hover:border-gray-400">
          <font-awesome-icon icon="fa-solid fa-times" />
        </button>
      </div>
      <div class="aspect-video">
        <iframe class="w-full h-full" :src="videoUrl" title="YouTube video" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>

    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.home-page {
  @apply bg-white;
}

/* set bg color of every other home-page section */
.home-page section:nth-child(even) {
  @apply bg-gray-50;
}

.home-page section:nth-child(odd) {
  @apply bg-white;
}


.home-page section {
  @apply py-2 pb-0 shadow-gray-100;
}
</style>
