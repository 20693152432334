<script setup>
import { ref, onMounted } from 'vue';
import AnimatedNumber from './AnimatedNumber.vue';


// Example stats
const stats = ref({
    courses: 145244,
    reviewCycles: 243228,
    comments: 1973939,
    uploaders: 2722,
    reviewers: 599399,
    developers: 109739,
    users: 206016
});

onMounted(() => {
    fetch("/api/object-stats")
        .then((response) => response.json())
        .then((data) => {
            stats.value = data;
        });
});


</script>

<template>
    <div class="mx-auto w-fit mt-8">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 lg:divide-x">
            <div class="stat-card">
                <h3 class="text-3xl font-bold ">
                    <AnimatedNumber trigger="onscreen" :targetNumber="stats.comments" />
                </h3>
                <h3 class=" text-xl font-semibold ">Comments</h3>
            </div>
            <div class="stat-card">

                <h3 class="text-3xl font-bold">
                    <AnimatedNumber trigger="onscreen" :targetNumber="stats.courses" />
                </h3>
                <h3 class=" text-xl font-semibold">Courses</h3>

            </div>
            <div class="stat-card">

                <h3 class=" text-2xl lg:text-3xl font-bold">
                    <AnimatedNumber trigger="onscreen" :targetNumber="stats.reviewers" />
                </h3>
                <h3 class="text-xl font-semibold">Reviewers</h3>
            </div>
            <div class="stat-card">

                <h3 class="text-2xl lg:text-3xl font-bold">
                    <AnimatedNumber trigger="onscreen" :targetNumber="stats.developers" />
                </h3>
                <h3 class="text-xl font-semibold ">Developers</h3>
            </div>
        </div>

    </div>

</template>

<style scoped>
.stat-card {
    @apply w-64 flex flex-col items-center text-blue-700;
}
</style>
