// stores/userStore.js
import { defineStore } from "pinia";
import axios from "axios";
import { useUserStore } from "./userStore";
import { useToastStore } from "./toastStore";

const apiInstance = axios.create();

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      const userStore = useUserStore();
      userStore.logout();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);



function hexToRGB(hex) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
}

export const useOrganizationStore = defineStore("organization", {
  state: () => ({
    organization: { exists: false },
    organizationInfoLoading: false,
    features: {
      courses: 0,
      archivable: false,
      branding: false,
      uploaders: 0,
      attachments: false,
    },
    subdomain: ''
  }),
  getters: {},
  actions: {
    async getOrganizationInfo() {
      this.organizationInfoLoading = true
      const response = await apiInstance.get("/api/organization/info");
      this.organization = response.data;
      this.organization.exists = true;
      document.documentElement.style.setProperty(
        "--font-color",
        this.organization.font_color
      );
      document.documentElement.style.setProperty(
        "--base-color",
        this.organization.base_color
      );
      //convert colors to rgba for better contrast
      let baseColor = this.organization.base_color;
      let fontColor = this.organization.font_color;
      let baseColorRGB = hexToRGB(baseColor);
      let fontColorRGB = hexToRGB(fontColor);
      document.documentElement.style.setProperty(
        "--base-color-rgb",
        baseColorRGB
      );
      document.documentElement.style.setProperty(
        "--font-color-rgb",
        fontColorRGB
      );
      this.organizationInfoLoading = false
    },
    async getOrganizationFeatures() {
      if (this.organization.owner) {
        const response = await apiInstance.get(
          `/api/organization/features/${this.organization.owner._id}`
        );
        this.features = response.data;
      } else {
        this.features = {
          courses: 0,
          archivable: false,
          branding: false,
          uploaders: 0,
          attachments: false,
        };
      }
    },
    //add/remove course from portfolio
    async updatePortfolioCourses(courseId) {
      const response = await apiInstance.put(
        `/api/organization/portfolio/${courseId}`
      );
      this.organization = response.data;
    },
    //update portfolio info
    async updatePortfolioInfo() {
      const toastStore = useToastStore()
      toastStore.showPortfolioSaveToast = true
      const response = await apiInstance.put("/api/organization/portfolio", {
        about: this.organization.about,
        contact_email: this.organization.contact_email,
        home_page: this.organization.home_page,
      });
      this.organization = response.data;
      setTimeout(() => {
        toastStore.showPortfolioSaveToast = false
      }, 2000)
    },
  },
  persist: true,
});