<template>
    <div class="space-y-8">
        <div class="mx-auto md:text-3xl font-bold text-gray-700 text-center">We play nicely with</div>
        <div class=" overflow-hidden whitespace-nowrap ">
            <div class=" inline-flex animate-scroll">
                <div v-for="(set, index) in [0, 1]" :key="index" class="flex ">
                    <img v-for="logo in logos" :key="`${index}-${logo.alt}`"
                        class="max-h-12 w-auto min-w-36 mx-1 object-contain " :src="logo" loading="lazy" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
const logos = ref([
    '/logos/tools/image1.png',
    '/logos/tools/image2.png',
    '/logos/tools/image3.png',
    '/logos/tools/image4.png',
    '/logos/tools/image5.png',
    '/logos/tools/image6.png',
    '/logos/tools/image7.png',
    '/logos/tools/image8.png',
    '/logos/tools/image9.png',
    '/logos/tools/image10.png',
    '/logos/tools/image11.png',
    '/logos/tools/image12.png',
    '/logos/tools/image13.png',
]);


</script>

<style scoped>
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.animate-scroll {
    animation: scroll 90s linear infinite;
}
</style>
