<template>
    <div class="relative inline-block" @mouseover="showTooltip" @mouseleave="hideTooltip" @click="showTooltip">

        <font-awesome-icon icon="fa-solid fa-info-circle " class="h-4 w-4 text-gray-400 mr-2" />

        <transition name="fade">
            <div v-if="show" class="absolute z-10 bg-white p-4 rounded-lg shadow-md w-64 tooltip-content">
                <div class="tooltip-arrow"></div>
                <slot name="tooltip"></slot>
            </div>
        </transition>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const show = ref(false)

const showTooltip = () => {
    show.value = true
}

const hideTooltip = () => {
    show.value = false
}

const toggleTooltip = () => {
    show.value = !show.value
}


</script>

<style scoped>
.tooltip-content {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 8px;
}

.tooltip-arrow {
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid white;

}
</style>