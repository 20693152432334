import { defineStore } from "pinia";


export const useToastStore = defineStore("toast", {
    state: () => ({
        showDescriptionSaveToPortfolioCourseMessage: false,
        showPortfolioSaveToast: false,
        showOrganizationSaveToast: false
    }),
    persist: true,
});
