<template>
    <div class="py-6 ">
        <div class="mx-auto">

            <h2 class="text-3xl font-extrabold text-center sm:text-4xl mb-4">
                One Platform, Complete eLearning Review Solution
            </h2>
            <p class="text-xl text-center  mb-12">
                See how our powerful eLearning review tool stacks up against the competition
            </p>
            <div class="bg-white rounded-lg shadow-xl overflow-hidden max-w-4xl mx-auto">
                <div
                    class="grid grid-cols-3 gap-4 p-6 bg-gradient-to-r from-fuchsia-600 to-blue-600  text-white font-semibold">
                    <div>Feature</div>
                    <div class="text-center">Review My eLearning</div>
                    <div class="text-center">Others</div>
                </div>
                <div class="divide-y divide-gray-200">
                    <TransitionGroup name="feature-list">
                        <div v-for="(feature, index) in displayedFeatures" :key="feature.name"
                            class="grid grid-cols-3 gap-3 md:gap-4 py-2 px-4 md:p-4 hover:bg-gray-50">
                            <div class="flex items-center">
                                <InfoToolTip><template v-slot:tooltip>
                                        {{ feature.description }}
                                    </template> {{ feature.name }}</InfoToolTip>
                            </div>
                            <div class="flex justify-center">
                                <div
                                    class="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center cursor-pointer transition-transform duration-300 hover:scale-110">
                                    <font-awesome-icon icon=" fa-solid fa-check" class="h-5 w-5 text-white" />
                                </div>
                            </div>
                            <div class="flex justify-center">
                                <div v-if="feature.others === true"
                                    class="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center cursor-pointer transition-transform duration-300 hover:scale-110">
                                    <font-awesome-icon icon=" fa-solid fa-check" class="h-5 w-5 text-white" />
                                </div>
                                <div v-else-if="feature.others === 'partial'"
                                    class="w-8 h-8 rounded-full bg-yellow-500 flex items-center justify-center cursor-pointer transition-transform duration-300 hover:scale-110">
                                    <div class="w-4 h-1 bg-white rounded-full"></div>
                                </div>
                                <div v-else
                                    class="w-8 h-8 rounded-full bg-red-500 flex items-center justify-center cursor-pointer transition-transform duration-300 hover:scale-110">
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="h-5 w-5 text-white" />
                                </div>
                            </div>
                        </div>
                    </TransitionGroup>
                </div>
                <div v-if="features.length > 5" class="p-4 bg-gray-50 text-center">
                    <button @click="toggleExpandedFeatures"
                        class="px-4 py-2 border rounded-md text-fuchsia-600 hover:text-fuchsia-800 border-fuchsia-600 hover:border-fuchsia-800 transition-colors duration-300">
                        <span v-if="expandedFeatures">
                            Show Less
                            <font-awesome-icon :icon="['fas', 'chevron-up']" />
                        </span>
                        <span v-else>
                            Show All Features
                            <font-awesome-icon :icon="['fas', 'chevron-down']" />
                        </span>
                    </button>
                </div>
            </div>
            <p class="mt-8 text-center text-white">
                * Comparison based on typical features found in other eLearning review tools. Individual products may
                vary.
            </p>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import InfoToolTip from './InfoToolTip.vue';

const features = [
    { name: 'Branding', rme: true, others: false, description: 'Customize the review interface and emails with your brand colors and logo.' },
    { name: 'Portfolio', rme: true, others: false, description: 'Showcase and organize all your eLearning projects in one place.' },
    { name: 'LX Replay', rme: true, others: false, description: 'Replay the learner experience exactly as they experienced it, including animations and interactions.' },
    { name: 'AI Reviewers', rme: true, others: false, description: 'Utilize AI-powered reviewers for automated feedback and quality checks.' },
    { name: '@mentions', rme: true, others: false, description: 'Tag team members in comments for better collaboration and communication.' },
    { name: 'Comment Statuses', rme: true, others: 'partial', description: 'Track the status of each comment (e.g., New, In Progress, Resolved).' },
    { name: 'Comment Categories', rme: true, others: false, description: 'Organize comments by categories for easier management.' },
    { name: 'Assign comments', rme: true, others: 'partial', description: 'Assign comments to Developers or Reviewers for follow up.' },
    { name: 'Jump to slide', rme: true, others: false, description: 'Quickly navigate to specific slides or sections of your eLearning course.' },
    { name: 'Compatibility with many Authoring tools', rme: true, others: false, description: 'Seamlessly integrate with many eLearning authoring tools.' },
    { name: 'SCORM Log', rme: true, others: false, description: 'Access detailed SCORM logs for troubleshooting and analysis.' },
    { name: 'Export Comments to spreadsheet', rme: true, others: false, description: 'Export all comments and feedback to a spreadsheet for offline review.' },
    { name: 'Attachments', rme: true, others: 'partial', description: 'Attach files and documents directly to comments and reviews.' },
]

const expandedFeatures = ref(false)

const displayedFeatures = computed(() => {
    return expandedFeatures.value ? features : features.slice(0, 5)
})

const toggleExpandedFeatures = () => {
    expandedFeatures.value = !expandedFeatures.value
}


</script>

<style scoped>
.feature-list-enter-active,
.feature-list-leave-active {
    transition: all 0.5s ease;
}

.feature-list-enter-from,
.feature-list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
</style>