<template>
    <div>
        <component :is="componentToRender"></component>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useOrganizationStore } from '../stores/organizationStore';
import Portfolio from './Portfolio.vue';
import Home from './Home.vue';

const organizationStore = useOrganizationStore();

const componentToRender = computed(() => {
    if (organizationStore.organization.subdomain) {
        return Portfolio;
    } else {
        return Home;
    }
});
</script>