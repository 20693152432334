<template>
    <span ref="numberElement">{{ formattedNumber }}</span>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';

const props = defineProps({
    targetNumber: {
        type: Number,
        required: true
    },
    duration: {
        type: Number,
        default: 3000 // Default duration in milliseconds
    },
    trigger: {
        type: String,
        default: 'onload' // Default trigger
    }
});

const displayedNumber = ref(0);
const numberElement = ref(null);

// Easing function (ease-out cubic)
const easeOutCubic = (t) => {
    return 1 - Math.pow(1 - t, 3);
};


const animateNumber = (start, end, duration) => {
    const startTime = performance.now();

    const update = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = easeOutCubic(progress);
        displayedNumber.value = Math.floor(easedProgress * (end - start) + start);


        if (progress < 1) {
            requestAnimationFrame(update);
        }
    };

    requestAnimationFrame(update);
};

const formattedNumber = computed(() => {
    return displayedNumber.value.toLocaleString();
});

onMounted(() => {
    const startNumber = Math.floor(props.targetNumber / 2);
    if (props.trigger === 'onload') {
        animateNumber(startNumber, props.targetNumber, props.duration);
    } else if (props.trigger === 'onscreen') {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                animateNumber(startNumber, props.targetNumber, props.duration);
                observer.disconnect();
            }
        });

        if (numberElement.value) {
            observer.observe(numberElement.value);
        }
    }
});

watch(() => props.targetNumber, (newValue) => {
    animateNumber(displayedNumber.value, newValue, props.duration);
});
</script>

<style scoped></style>
