<template>
    <div class="">
        <div class="relative mx-auto py-8 md:py-1">
            <div class="mx-auto md:text-3xl font-bold text-gray-700 text-center">What our fans say</div>
            <div class="relative h-[520px] md:h-[480px] lg:h-[450px]">
                <!-- :class="testimonial.color"  -->
                <div v-for="(testimonial, index) in testimonials" :key="index"
                    class="bg-white border w-[430px] h-[490px] md:w-[700px] md:h-[380px] rounded-xl p-6 pb-4 shadow-lg transition-all duration-500 ease-in-out"
                    :style="getCardStyle(index)" @click="handleClick($event, index)">
                    <div class="flex flex-col h-full justify-between" :class="{ 'blur-xs': index !== currentIndex }">
                        <div class="w-fit h-12">
                            <img :src="testimonial.logo" :alt="`${testimonial.title}`" class="h-10 mb-2" />
                        </div>

                        <div class="">

                            <p class=" mb-2">{{ testimonial.quote }}</p>
                        </div>
                        <div class="flex items-center divide-x gap-4">
                            <img :src="testimonial.photo" :alt="testimonial.name"
                                class="size-16 md:size-20 rounded-full  border border-white" />

                            <div class="pl-4">
                                <h3 class="font-bold text-xl">{{ testimonial.name }}</h3>
                                <p class="">{{ testimonial.title }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button @click="prevTestimonial"
                class="absolute left-4 md:left-20 xs:bottom-12 md:top-1/2 transform -translate-y-1/2 -translate-x-1/2 rounded-full p-2 bg-white shadow-md hover:bg-gray-100 z-10">
                <font-awesome-icon :icon="['fas', 'chevron-left']" class="h-6 w-6" />
            </button>
            <button @click="nextTestimonial"
                class="absolute right-4 md:right-20 xs:bottom-12 md:top-1/2 transform -translate-y-1/2 translate-x-1/2 rounded-full p-2 bg-white shadow-md hover:bg-gray-100 z-10">
                <font-awesome-icon :icon="['fas', 'chevron-right']" class="h-6 w-6" />
            </button>
        </div>

    </div>
</template>

<script setup>
import { ref } from 'vue'


const testimonials = [
    {
        title: "The Captivate Teacher", name: "Paul Wilson", quote: "Best Way to Share #eLearning for Review. Review My eLearning keeps all of your feedback in a single place and you know exactly which feedback is for which portion of your eLearning course. You can set it up so that your reviewers can see each other's feedback. And of course, its all in one place. That's my favorite part about it.", photo: "/imgs/paulwilson.jpg",
        color: "bg-[#6bab3a] text-white",
        logo: "/imgs/logopaulwilson.png",
    },
    {
        title: "Connect 4 Education", name: "Anita Horsley", quote: "Review My eLearning makes it easy for my team to give feedback and stay aligned, even with non-tech-savvy stakeholders.", photo: "/imgs/ahorsley.jpg",
        color: "bg-[#0fa9e2] text-white",
        logo: "/imgs/connect4education.png",
    },
    {
        title: "Commlab India",
        name: "Payal Dixit",
        quote: "...an intuitive and efficient interface that makes eLearning course reviews incredibly easy. SMEs and stakeholders can offer their feedback in the 'comments' section and post their suggestions. You can also change the settings to allow private comments. Each feedback is streamlined, making it easier to implement changes. ... The Comments section has simple text fields to write feedback and checkboxes to indicate what is being reviewed.", photo: "/imgs/payaldixit.jpg",
        color: "bg-[#6bab3a] text-white",
        logo: "/imgs/logocomlab.png",
    },
    {
        title: "Connect 4 Education",
        name: "Anita Horsley",
        quote: "I did a lot of research and I found that Review My eLearning is the best one out there for developers and reviewers.",
        photo: "/imgs/ahorsley.jpg",
        color: "bg-[#0fa9e2] text-white",
        logo: "/imgs/connect4education.png",
    },
    {
        logo: "/imgs/logoei.png",
        title: "eLearning Industry", name: "Christopher Pappas", quote: "A user-friendly eLearning course reviewer that allows you to organize and track all of your comments with ease. You can invite specific collaborators, manage email correspondence, and even make certain comments private. This platform also gives you the ability to create assignments and monitor or approve changes without using a third-party tool. Review My eLearning is encrypted with a 128-bit TLS 1.2 connection. So, you don't have to worry about the safety of your sensitive data.", photo: "/imgs/ChristopherPappas.jpg",
        color: "bg-[#850c70] text-white",
    },
]

//randomize testimonials
//testimonials.sort(() => Math.random() - 0.5)

const currentIndex = ref(0)

const nextTestimonial = () => {
    currentIndex.value = (currentIndex.value + 1) % testimonials.length
}

const prevTestimonial = () => {
    currentIndex.value = (currentIndex.value - 1 + testimonials.length) % testimonials.length
}

const handleClick = (event, index) => {
    const clickX = event.clientX;
    const divWidth = event.currentTarget.clientWidth;
    if (clickX < divWidth / 2) {
        prevTestimonial();
    } else {
        nextTestimonial();
    }
};

const getCardStyle = (index) => {
    const totalCards = testimonials.length
    let offset = index - currentIndex.value

    // Ensure offset wraps around properly
    if (offset > totalCards / 2) offset -= totalCards
    if (offset < -totalCards / 2) offset += totalCards

    const isActive = offset === 0
    const zIndex = totalCards - Math.abs(offset)

    // Calculate horizontal offset with diminishing returns
    const xOffset = Math.sign(offset) * (10 + Math.abs(offset) * 10)

    // Calculate vertical offset
    const yOffset = Math.abs(offset) * 6

    // Calculate scale
    const scale = 1 - Math.abs(offset) * 0.05

    //check card size
    if (window.innerWidth < 768) {
        return {
            transform: `translateX(${xOffset}%) translateY(${yOffset}%) scale(${scale})`,
            zIndex,
            opacity: isActive ? 1 : 0.8 - Math.abs(offset) * 0.1,
            transition: 'all 0.3s ease',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-52%', // Half the width of the card
            marginTop: '-60%',  // Half the height of the card
        }
    }

    return {
        transform: `translateX(${xOffset}%) translateY(${yOffset}%) scale(${scale})`,
        zIndex,
        opacity: isActive ? 1 : 0.8 - Math.abs(offset) * 0.1,
        transition: 'all 0.3s ease',
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: '-350px', // Half the width of the card
        marginTop: '-200px',  // Half the height of the card
    }
}
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>