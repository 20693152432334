<script setup>
import { ref, onMounted } from "vue";
import { useOrganizationStore } from "../stores/organizationStore.js";
import { useUserStore } from "../stores/userStore.js";
import { Clipboard } from "v-clipboard";
import Toast from "../components/toast/Toast.vue";

const organizationStore = useOrganizationStore();
const userStore = useUserStore();

const portfolio_courses = ref([]);
const portfolioURL = ref(window.location.origin);
const showAlert = ref(false);
const showToast = ref(false);

//on mount check route parama to see if we should show signup
onMounted(() => {
  //set page title to organization name
  document.title = organizationStore.organization.title + " Portfolio";
  portfolio_courses.value = organizationStore.organization.portfolio_courses;
  //if the user is the org owner, show the alert
  if (userStore?.user?._id === organizationStore?.organization?.owner?._id) {
    showAlert.value = true;
  }
});
// open course when card is clicked
const openCourse = (course) => {
  window.open(`/course-review/${course._id}?mode=portfolio`, "_blank");
};

const copyToClipboard = () => {
  Clipboard.copy(window.location.origin);
  showToast.value = true;
  posthog.capture("portfolio_link copied");
  setTimeout(() => {
    showToast.value = false;
  }, 2000);
};
</script>

<template>
  <div class="my-[25%] mx-[50%]" v-if="organizationStore.organizationInfoLoading">
    <span class="absolute loading loading-spinner w-[50px] text-gray-400"></span>
  </div>
  <div v-else class="flex-grow">
    <div class="bg-white py-6">
      <div class="flex mx-auto max-w-4xl">
        <div v-if="showAlert" role="alert" class="alert shadow-lg mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info shrink-0 w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <div>
            <h3 class="font-bold">Your Portfolio URL</h3>
            <div class="">
              To share your Portfolio copy this URL:
              <span class="font-semibold tooltip tooltip-top" data-tip="Click to copy" @click="copyToClipboard()">
                {{ portfolioURL }}
              </span>
            </div>

            <div class="text-xs">Your visitors will not see this message</div>
          </div>
          <button @click="showAlert = false"
            class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 self-start">
            <span class="sr-only">Close</span>
            <svg class="size-6" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path
                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="mx-auto max-w-7xl">
        <h2 class="text-center text-4xl font-bold tracking-wide text-gray-900 sm:text-6xl">
          {{ organizationStore.organization.title }}
        </h2>
        <p class="mt-6 prose" v-html="organizationStore.organization.about"></p>
        <div class="mt-6 flex justify-center gap-4">
          <a v-if="organizationStore.organization.contact_email"
            :href="`mailto:${organizationStore.organization.contact_email}`" class="rme-btn rme-btn-primary"
            target="_blank">Contact Us</a>
          <a v-if="organizationStore.organization.home_page" :href="organizationStore.organization.home_page"
            target="_blank" class="rme-btn rme-btn-primary">Visit Website</a>
        </div>
      </div>
    </div>
    <div>
      <div v-if="
        (organizationStore.organization.portfolio_courses.length === 0 ||
          !organizationStore.organization.about) &&
        showAlert
      "
        class="mx-auto mt-3 max-w-2xl p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <a href="#">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Create your Portfolio
          </h5>
        </a>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          To create your portfolio you should first add some infomation about your
          organization.
        </p>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          Then navigate to one of your courses and toggle the "Include in my portfolio."
          switch.
        </p>
        <a href="/profile#portfolio" aria-label="Go to Settings"
          class="inline-flex items-center px-3 py-2 rme-btn rme-btn-primary">
          Go to Settings
          <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9" />
          </svg>
        </a>
      </div>
    </div>
    <ul role="list" class="flex flex-wrap justify-center gap-3 mt-6 px-6 pb-24">
      <li v-for="card in organizationStore.organization?.portfolio_courses" class="portfolio-card cursor-pointer"
        @click="openCourse(card)">
        <div class="flex flex-1 flex-col mb-2">
          <img class="w-full h-64 object-cover rounded-lg border-b border-gray-200"
            :src="card?.thumbnail ?? 'imgs/thumbnail.jpg'" @error="(event) => (event.target.src = 'imgs/thumbnail.jpg')"
            alt="thumbnail" loading="lazy" />

          <h3 class="prose p-2 border-b-2 border-gray-50 font-medium text-gray-900">
            {{ card.title }}
          </h3>
          <p class="prose text-left p-2" v-html="card.description"></p>
        </div>
      </li>
    </ul>
  </div>
  <Toast v-show="showToast" message="Copied URL to Clipboard" />
</template>

<style>
.portfolio-card {
  @apply w-80 p-2 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow hover:bg-gray-50 hover:shadow-md;
}

[v-cloak] {
  display: none;
}
</style>
