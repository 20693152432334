<template>
    <div class="blog-feed pt-4">
        <h2 class="text-3xl font-bold text-center mb-4">From our Blog</h2>
        <div v-if="loading" class="text-center text-xl">Loading...</div>
        <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div v-for="(post, index) in posts" :key="index"
                class="bg-white border border-gray-100 rounded-lg p-4 shadow">
                <h3 class="text-xl font-bold mb-2">{{ post.title }}</h3>
                <p class="text-base mb-4" v-html="post.description"></p>
                <a :href="post.link" target="_blank" class="text-blue-500 hover:underline">Read more</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { parseStringPromise } from 'xml2js';

const posts = ref([]);
const loading = ref(true);

const fetchRSSFeed = async () => {
    try {
        const response = await axios.get('https://blog.reviewmyelearning.com/rss.xml');
        const result = await parseStringPromise(response.data);
        const items = result.rss.channel[0].item.slice(0, 4);
        posts.value = items.map(item => ({
            title: item.title[0],
            description: item.description[0],
            link: item.link[0],
        }));
    } catch (error) {
        console.error('Error fetching RSS feed:', error);
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    fetchRSSFeed();
});
</script>

<style scoped></style>